import { create } from "zustand";

export const useStore = create<Store>()((set) => ({
  tagsData: [],
  setTagsData: (tagsData: []) => set({ tagsData }),
  globalPostalCode: "",
  setGlobalPostalCode: (globalPostalCode: string) => set({ globalPostalCode }),
  globalHouseNumber: "",
  setGlobalHouseNumber: (globalHouseNumber: string) => set({ globalHouseNumber }),
  globalLocation: {},
  setGlobalLocation: (globalLocation: string) => set({ globalLocation }),
  globalBirthdate: "",
  setGlobalBirthdate: (globalBirthdate: string) => set({ globalBirthdate }),
  cookiesPreferences: false,
  setCookiesPreferences: (cookiesPreferences: boolean) => set({ cookiesPreferences }),
}));

interface Store {
  tagsData: [];
  setTagsData: (tagsData: []) => void;
  globalPostalCode: string;
  setGlobalPostalCode: (globalPostalCode: string) => void;
  globalHouseNumber: string;
  setGlobalHouseNumber: (globalHouseNumber: string) => void;
  globalLocation: any;
  setGlobalLocation: (globalLocation: any) => void;
  globalBirthdate: string;
  setGlobalBirthdate: (globalBirthdate: string) => void;
  cookiesPreferences: boolean;
  setCookiesPreferences: (cookiesPreferences: boolean) => void;
}
