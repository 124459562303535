"use client";

import Link from "next/link";

import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import Arrow from "./icons/Arrow";
import { cn, getTextColor } from "@/lib/utils";

interface RichTextProps {
  data: any;
  className?: string;
  bgColor?: string;
}

function processParagraphs(paragraphs: any) {
  paragraphs.forEach((paragraph: any) => {
    if (isLinkOnly(paragraph)) {
      paragraph.children.forEach((child: any) => {
        if (child.type === "link" && child.children) {
          child.children.forEach((linkChild: any) => {
            if (linkChild.type === "text" && !linkChild.text.includes("-linkonly")) {
              linkChild.text += "-linkonly";
            }
          });
        }
      });
    }
  });
}

function isLinkOnly(paragraph: any) {
  if (paragraph.type === "paragraph" && paragraph.children && paragraph.children.length > 0) {
    let linkFound = false;
    for (let child of paragraph.children) {
      if (child.type === "text" && child.text.trim() === "") {
        continue;
      } else if (child.type === "link") {
        if (linkFound) {
          return false;
        }
        linkFound = true;
      } else {
        return false;
      }
    }
    return linkFound;
  }
  return false;
}

function containsIframe(children: any[]) {
  return children.some((child: any) => {
    if (typeof child?.props?.text === "string" && child.props.text.trim().startsWith("<iframe")) {
      return true;
    }
    return false;
  });
}

export default function RichText({ data, className = "rich-text relative", bgColor }: RichTextProps) {
  processParagraphs(data);

  return (
    <section className={cn(className, getTextColor(bgColor) === "#ffffff" && "text-white")}>
      <BlocksRenderer
        content={data}
        blocks={{
          paragraph: ({ children }: any) => {
            if (containsIframe(children)) {
              return <div className="w-full" dangerouslySetInnerHTML={{ __html: children[0]?.props?.text }} />;
            }

            return <p>{children}</p>;
          },
          image: ({ image }) => {
            return (
              <img
                className="rounded"
                src={image.url.replace("localhost", "127.0.0.1")}
                alt={image.alternativeText || ""}
              />
            );
          },
          link: ({ children, url }) => {
            const external = url.startsWith("http");
            const childText = Array.isArray(children) && children[0]?.props?.text;
            if (childText?.includes("-linkonly") && Array.isArray(children)) {
              const newChildren = children.map((child) => {
                return {
                  ...child,
                  props: {
                    text: childText.replace("-linkonly", ""),
                  },
                };
              });

              return external ? (
                <a href={url} target="_blank" className="linkonly group" rel="noopener noreferrer ">
                  {newChildren}{" "}
                  <Arrow
                    color={getTextColor(bgColor)}
                    className="ml-3 block min-w-fit transition-transform group-hover:translate-x-1"
                  />
                </a>
              ) : (
                <Link className="linkonly group flex" href={url}>
                  {newChildren}{" "}
                  <Arrow
                    color={getTextColor(bgColor)}
                    className="ml-3 inline-block min-w-fit transition-transform group-hover:translate-x-1"
                  />
                </Link>
              );
            }

            return external ? (
              <a href={url} target="_blank" rel="noopener noreferrer">
                {children}
              </a>
            ) : (
              <Link href={url}>{children}</Link>
            );
          },
        }}
      />
    </section>
  );
}
